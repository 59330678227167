import { useEffect, useContext, useMemo } from 'react';
import JobContext from '../../store/job/job-context';
import { Tree } from '../../store/job/job-data';
import { getCadFileNameProperty } from '../../utils/hoops.utils';

interface TreeViewProps {
    Hwv: Communicator.WebViewer,
    reloadTreeStructure: number
}

export default function TreeviewStructure(props: TreeViewProps) {
    const jobContext = useContext(JobContext);

    const isTreeLoaded = useMemo(() => jobContext && Object.keys(jobContext.Tree).length > 0 , [jobContext]);

    useEffect(() => {
        async function ExploreTree() {
            if (!props.Hwv) return;

            const treeDictionary: Tree = {};
            await ExploreChildren(treeDictionary, props.Hwv.model.getAbsoluteRootNode(), true, '/');
            
            jobContext.setTree(treeDictionary);
        }

        function GetAllDescendantNodes(descendants: number[], nodeId: number) {
            const nodeIds = [nodeId];

            while (nodeIds.length) {
                const currentNodeId = nodeIds.pop();

                if (currentNodeId) {
                    const currentNodeChildrenIds = props.Hwv.model.getNodeChildren(currentNodeId);

                    descendants.push.apply(descendants, currentNodeChildrenIds);
                    nodeIds.push.apply(nodeIds, currentNodeChildrenIds);
                }
            }
        }

        async function ExploreChildren(treeDictionary: Tree, currentNodeId: number, isRoot: boolean, path: string, cadFileName: string | null = null) {
            const currentNodeChildren = props.Hwv.model.getNodeChildren(currentNodeId);
            const groupedNodes = new Map<string, number[]>();

            for(var childrenNodeId of currentNodeChildren){
                var nodeName = props.Hwv.model.getNodeName(childrenNodeId) ?? "";
                var currentGroup = groupedNodes.get(nodeName);
                groupedNodes.set(nodeName, currentGroup ? [childrenNodeId, ...currentGroup] : [childrenNodeId]);
            }

            for (let grouped of groupedNodes) {
                var key = grouped[0];
                var group = grouped[1];
                if (group.length === 1) {
                    const singleNodeId = group[0];
                    if (isRoot) {
                        cadFileName = await getCadFileNameProperty(singleNodeId, props.Hwv);
                    }

                    switch (props.Hwv.model.getNodeType(singleNodeId)) {
                        case Communicator.NodeType.Part:
                        case Communicator.NodeType.PartInstance:
                        case Communicator.NodeType.BodyInstance:
                        case Communicator.NodeType.AssemblyNode: {
                            const currentPath = `${path}${key}/`;
                            treeDictionary[currentPath] = {
                                nodeIds: [singleNodeId],
                                name: key,
                                isSelected: false,
                                isVisible: true,
                                path: currentPath,
                                cadFileName: cadFileName
                            };
                            await ExploreChildren(treeDictionary, singleNodeId, false, currentPath, cadFileName);
                            break;
                        }

                        default:
                            break;
                    }
                } else {
                    let descendants: number[] = [];
                    for (let node of group) {
                        if (isRoot) {
                            cadFileName = await getCadFileNameProperty(node, props.Hwv);
                        }

                        let currentDescendants: number[] = [];
                        GetAllDescendantNodes(currentDescendants, node);
                        descendants.push.apply(descendants, currentDescendants);
                    }

                    const currentPath = `${path}${key}/`;
                    treeDictionary[currentPath] = {
                        nodeIds: group.concat(descendants),
                        name: `${key} x ${group.length}`,
                        isSelected: false,
                        isVisible: true,
                        path: currentPath,
                        cadFileName: cadFileName
                    };
                }
            }
        }

        ExploreTree();
    }, [props.Hwv, props.reloadTreeStructure]);

    useEffect(() => {
        jobContext?.setIsTreeLoaded(isTreeLoaded);
    }, [isTreeLoaded])

    return (<></>)
}