import { createContext, Dispatch, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { TreeviewItemModel } from "../../components/job/TreeviewNodeModel";
import { getAllDescendantBodyIds, makeFaceKey, parseFaceKey, selectNodeIds, SessionNodeId } from "../../utils/hoops.utils";
import { Channel } from "./channel";
import { Part, Tree, TreeviewFaceGroup } from "./job-data";
import { useUpdateOnChange } from "../../hooks/useUpdateOnChange";
import JobContext from "./job-context";
import { SyntheticMesh, useVisibilityReducer, VisibilityMode, VisibilityStateAction, VisibilityStateActionType } from "./hoops-visibility-reducer";

export type HoopsVisiblityContextType = {
    mode: VisibilityMode,
    updateVisibility: Dispatch<VisibilityStateAction>,
    getVisibility: (item: Part | TreeviewFaceGroup) => boolean,
    setSelectedNodes: (nodeIds: SessionNodeId[]) => void,
    reset: () => void,
    onReset: (cb: ResetCallback) => Function
}

export const HoopsVisibilityContext = createContext<HoopsVisiblityContextType>({
    updateVisibility: () => null,
    getVisibility: () => true,
    setSelectedNodes: () => null,
    reset: () => null,
    onReset: (_: ResetCallback) => () => null,
    mode: VisibilityMode.REGULAR
})

type FaceVisibilityRecord = {
    visible: number[],
    hidden: number[]
}

type ResetCallback = () => void;

export function useVisibilityContext(syntheticMeshes: SyntheticMesh[], hwv: Communicator.WebViewer | null) {
    const jobContext = useContext(JobContext);
    const [changesToNodeVisibility, setNodeVisibilityMap] = useState<Map<number, boolean>>(new Map());
    const [changesToFaceVisibility, setFaceVisibilityMap] = useState<Map<number, FaceVisibilityRecord>>(new Map());
    const meshes = useUpdateOnChange(syntheticMeshes);
    const getRelatedBodyNodeIds = useCallback((nodeIds: number[]) => hwv !== null ? getAllDescendantBodyIds(nodeIds, hwv.model) : [], [hwv]);
    const treeNodeItemNodeIdMap = useMemo(() => {
        const map = new Map<number, TreeviewItemModel>();

        if (jobContext.IsTreeLoaded) {
            const items = Object.values(jobContext.Tree);

            for (const item of items) {
                item.nodeIds.forEach(nodeId => map.set(nodeId, item));
            }
        }
        return map;
    }, [jobContext.IsTreeLoaded]);
    const resetCallbacks = useRef<Set<ResetCallback>>(new Set());

    const [state, dispatch] = useVisibilityReducer(getRelatedBodyNodeIds);

    const getVisibility = useCallback((item: Part | TreeviewFaceGroup): boolean => {
        if (Channel.isTreeviewFaceGroup(item)) {
            return item.config.every(face => state.faceVisibilityMap.get(makeFaceKey(face)) === true);
        } else {
            return item.nodesIds.every(nodeId => {
                return state.nodeVisibilityMap.get(nodeId) === true;
            });
        }
    }, [state]);

    const setSelectedNodes = useCallback((nodeIds: SessionNodeId[]): void => {
        // TODO On principle, automatic selection in the callback selectArray
        //      should be temporarily disabled here. The selection should be
        //      taken as specified. In practice, smart selection should converge
        //      to the same result, so it should not matter.
        if (hwv && nodeIds.length > 0) {
            selectNodeIds(nodeIds, hwv);
        }
    }, [state]);

    const updateStateFromHoops = useCallback((showBodyIds: number[], hideBodyIds: number[]) => {
        if (hideBodyIds.length === 0) {
            dispatch({
                type: VisibilityStateActionType.SHOW_ALL
            });
        } else {
            const nodeVisibilities = [
                ...showBodyIds.map(nodeId => [nodeId, true]) as [[number, boolean]],
                ...hideBodyIds.map(nodeId => [nodeId, false]) as [[number, boolean]],
            ]
            dispatch({
                type: VisibilityStateActionType.UPDATE_STATE,
                nodeVisibilityMap: new Map<number, boolean>([...nodeVisibilities])
            })
        }
    }, [getRelatedBodyNodeIds]);

    const reset = () => {
        resetCallbacks.current.forEach(cb => cb());
    };

    const onReset = (cb: ResetCallback) => {
        resetCallbacks.current.add(cb);

        return () => {
            resetCallbacks.current.delete(cb);
        }
    }


    useEffect(() => {
        dispatch({
            type: VisibilityStateActionType.UPDATE_STATE,
            syntheticMeshVisibilityMap: new Map<number, boolean>(meshes.map(m => [m.nodeId, true]))
        })
    }, [meshes]);

    useEffect(() => {
        if (state.isSilent) {
            return;
        }

        const changesToNodeVisibility: Map<number, boolean> = new Map();
        const changesToFaceVisibility: Map<number, {
            visible: number[],
            hidden: number[]
        }> = new Map();


        for (const [nodeId, visibility] of state.nodeVisibilityMap.entries()) {
            changesToNodeVisibility.set(nodeId, visibility);
        }

        for (const [nodeId, visibility] of state.syntheticMeshVisibilityMap.entries()) {
            changesToNodeVisibility.set(nodeId, visibility);
        }


        setNodeVisibilityMap(changesToNodeVisibility);

        let needsNodeVisibilityUpdate = false;

        for (const [key, visibility] of state.faceVisibilityMap.entries()) {
            const face = parseFaceKey(key);
            const nodeVisibility = state.nodeVisibilityMap.get(face.nodeId);
            const record = changesToFaceVisibility.get(face.nodeId) || {
                visible: [],
                hidden: []
            };

            changesToFaceVisibility.set(face.nodeId, record);

            if (nodeVisibility === visibility) {
                continue;
            }

            if (visibility === false) {
                record.hidden.push(face.faceIndex);
            } else {
                record.visible.push(face.faceIndex);
            }

            changesToNodeVisibility.set(face.nodeId, true);
            needsNodeVisibilityUpdate = true;
        }

        needsNodeVisibilityUpdate && setNodeVisibilityMap(changesToNodeVisibility);

        setFaceVisibilityMap(changesToFaceVisibility);
    }, [state]);

    useEffect(() => {
        async function exec(changesToNodeVisibility: Map<number, boolean>, hwv: Communicator.WebViewer) {
            hwv.unsetCallbacks({
                visibilityChanged: updateStateFromHoops
            });

            await hwv.model.setNodesVisibilities(changesToNodeVisibility);

            for (const [nodeId, _] of changesToNodeVisibility) {
                const nodeType = hwv.model.getNodeType(nodeId);

                if (nodeType === Communicator.NodeType.BodyInstance) {
                    try {
                        hwv.model.clearNodeFaceVisibility(nodeId);
                        hwv.model.clearNodeLineVisibility(nodeId);
                    } catch (e) {
                        console.error(nodeId, e)
                    }
                }

            }

            hwv.setCallbacks({
                visibilityChanged: updateStateFromHoops
            });

        }
        if (hwv && changesToNodeVisibility.size) {
            exec(changesToNodeVisibility, hwv);
        }
    }, [hwv, changesToNodeVisibility, updateStateFromHoops]);

    useEffect(() => {
        async function exec(map: Map<number, FaceVisibilityRecord>, hwv: Communicator.WebViewer) {
            for (const [nodeId, record] of map.entries()) {
                const nodeType = hwv.model.getNodeType(nodeId);

                if (nodeType === Communicator.NodeType.BodyInstance) {
                    hwv.model.clearNodeFaceVisibility(nodeId);
                    hwv.model.clearNodeLineVisibility(nodeId);
    
                    if (record.hidden.length || record.visible.length) {
                        record.hidden.forEach(h => hwv.model.setNodeFaceVisibility(nodeId, h, false));
    
                        if (record.visible.length) {
                            const faceCount = await hwv.model.getFaceCount(nodeId);
    
                            for (let faceIndex = 0; faceIndex < faceCount; faceIndex++) {
                                hwv.model.setNodeFaceVisibility(nodeId, faceIndex, record.visible.includes(faceIndex));
                            }
                        }
    
                        const lineCount = await hwv.model.getEdgeCount(nodeId);
    
                        for (let lineIndex = 0; lineIndex < lineCount; lineIndex++) {
                            hwv.model.setNodeLineVisibility(nodeId, lineIndex, false);
                        }
                    }
                }
            }
        }

        if (hwv) {
            exec(changesToFaceVisibility, hwv);
        }
    }, [hwv, changesToFaceVisibility]);

    useEffect(() => {
        if (treeNodeItemNodeIdMap.size) {
            dispatch({
                type: VisibilityStateActionType.UPDATE_STATE,
                nodeVisibilityMap: new Map<number, boolean>([...treeNodeItemNodeIdMap.keys()].map(nodeId => [nodeId, true]))
            });
        }
    }, [treeNodeItemNodeIdMap]);

    useEffect(() => {
        hwv?.setCallbacks({
            visibilityChanged: updateStateFromHoops
        });

        return () => {
            hwv?.unsetCallbacks({
                visibilityChanged: updateStateFromHoops
            });
        }
    }, [hwv, updateStateFromHoops]);

    useEffect(() => {
        if (treeNodeItemNodeIdMap.size && changesToNodeVisibility.size > 0) {
            const subTree: Tree = {};

            for (const [nodeId, visibility] of changesToNodeVisibility) {
                const treeItem = treeNodeItemNodeIdMap.get(nodeId);

                if (treeItem) {
                    subTree[treeItem.path] = {
                        ...treeItem,
                        isVisible: visibility
                    }
                }
            };

            const newTree = {
                ...jobContext.Tree,
                ...subTree
            };

            jobContext.setTree(newTree);
        }
    }, [changesToNodeVisibility, treeNodeItemNodeIdMap]);


    return {
        state,
        getVisibility,
        updateVisibility: dispatch,
        setSelectedNodes,
        reset,
        onReset
    }
}